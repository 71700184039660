import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BoxRows from 'components/box/box-rows/box-rows';
import { FullWidthImg } from 'components/image-wrappers';
import Title from 'components/title';
import Layout from 'components/layout';
import Stack from 'components/stack';
import StackItem from 'components/stack/stack-item';
import {
  TextWrapper,
  TextUnderline,
  Body,
  Description,
  StickyText,
  ProjectBox,
  ProjectInfo,
  BackgroundText,
  NavigationWrapper,
  NavButtons,
  NavSubText,
  ImgTitle,
  ImgWrapper,
  HeroImgContainer,
  BodyImgWrapper
} from 'components/GlobalComponents';

const Couchsurfing = ({ data }) => {
  const project = data.couchsurfingJson;
  const [next, nextTitle] = ['/ceramics', 'Yonobi Ceramics'];

  return (
    <Layout>
      <HeroImgContainer>
        <FullWidthImg
          key={project.gallery.hero.id}
          opacity={0.1}
          fluid={project.gallery.hero.childImageSharp.fluid}
        />
        <ImgTitle>{project.title}</ImgTitle>
      </HeroImgContainer>

      <TextWrapper>
        <BoxRows>
          <Stack>
            <StackItem>
              <Title as="h4">ROLE</Title>
              <BackgroundText>{project.role}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">CONTEXT</Title>
              <BackgroundText>{project.context}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">YEAR</Title>
              <BackgroundText>{project.year}</BackgroundText>
            </StackItem>
            <StackItem>
              <Title as="h4">METHODS</Title>
              <BackgroundText
                dangerouslySetInnerHTML={{
                  __html: project.methods.childMarkdownRemark.html,
                }}
              ></BackgroundText>
            </StackItem>
          </Stack>
          <ProjectInfo>
            <Title as="h3">Overview</Title>
            <TextUnderline />
              <Description
                dangerouslySetInnerHTML={{
                  __html: project.overview.childMarkdownRemark.html,
                }}
              />
            <Title as="h4">Research Goals and Questions:</Title>
            <TextUnderline />
            <Description
              dangerouslySetInnerHTML={{
                __html: project.goals.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </BoxRows>

        <ProjectBox>
          <Title as="h4">Introduction</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.introduction.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

        <ProjectBox>
          <Title as="h4">Background:</Title>
          <TextUnderline />
          <ProjectInfo> 
            <Description
              dangerouslySetInnerHTML={{
                __html: project.background.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
          <ImgWrapper>
            <Img
              fluid={project.gallery.competitive.childImageSharp.fluid}
            />
          </ImgWrapper>
        </ProjectBox>

        <ProjectBox>
          <Title as="h4">Methodology:</Title>
          <TextUnderline />
          <ProjectInfo> 
            <Description
              dangerouslySetInnerHTML={{
                __html: project.methodology.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

      
        <ProjectBox>
          <Title as="h4">Conducting Ethnography</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.researchExecution.childMarkdownRemark.html,
              }}
            />
          <ImgWrapper>
            <Img
              fluid={project.gallery.ethnography.childImageSharp.fluid}
            />
          </ImgWrapper>
          </ProjectInfo>
          </ProjectBox>

          <ProjectBox>
          <Title as="h4">Conducting Interviews</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.recruitment.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

        <ProjectBox>
          <Title as="h3">Results & Analysis</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.results.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

        <ProjectBox>
          <Title as="h4">Dilemma 1: Time Spent with Requests</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.dilemma1.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

        <ProjectBox>
          <Title as="h4">Dilemma 2: Concern with Safety </Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.dilemma2.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

        <ProjectBox>
          <Title as="h4">Communicating Insights</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.communicateInsight.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>

        <ProjectBox>
          <Title as="h4">Key Takeaway and Next Steps:</Title>
          <TextUnderline />
          <ProjectInfo>
            <Description
              dangerouslySetInnerHTML={{
                __html: project.takeaway.childMarkdownRemark.html,
              }}
            />
          </ProjectInfo>
        </ProjectBox>
      </TextWrapper>

      <NavigationWrapper background={'#f7d68b'}>
        <Link to={`${next}`}>
          <NavButtons line>
            <NavSubText>Next &#8594;</NavSubText>
            <Title as="span">{nextTitle}</Title>
          </NavButtons>
        </Link>
      </NavigationWrapper>
    </Layout>
  );
};

export const query = graphql`
  query CouchsurfingQuery {
    couchsurfingJson {
      title
      year
      role
      context
      methods {
        childMarkdownRemark {
          html
        }
      }
      overview {
        childMarkdownRemark {
          html
        }
      }
      goals {
        childMarkdownRemark {
          html
        }
      }
      introduction {
        childMarkdownRemark {
          html
        }
      }
      background {
        childMarkdownRemark {
          html
        }
      }
      methodology {
        childMarkdownRemark {
          html
        }
      }
      researchExecution {
        childMarkdownRemark {
          html
        }
      }
      recruitment {
        childMarkdownRemark {
          html

        }
      }
      results {
        childMarkdownRemark {
          html
        }
      }
      dilemma1 {
        childMarkdownRemark {
          html
        }
      }
      dilemma2 {
        childMarkdownRemark {
          html
        }
      }
      communicateInsight {
        childMarkdownRemark {
          html
        }
      }
      takeaway {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        hero {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        competitive {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ethnography {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
export default Couchsurfing;
