import Img from 'gatsby-image';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const RegularImg = styled(Img)`
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 4em 4em 0 0;
`;

export const FullWidthImg = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100%;

  &::before {
    content: '';
    position: absolute;
    background: ${({ opacity }) =>
      opacity ? `rgba(0, 0, 0, ${opacity})` : 'rgba(0, 0, 0, 0)'};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;
